import React, { useEffect, useState } from "react";

import "./styles.css";
import feedback from "./../../Store/Feedback.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import BlogsDynamic from "../DynamicBlogs/BlogsDynamic";

const Smm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [comment, setComment] = useState("");
  let x = window.matchMedia("(max-width: 500px)");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      Name: name,
      Email: email,
      PhoneNo: phoneNo,
      Comment: comment,
      From: "SMM Course Page",
    };

    fetch("https://api.sheetmonkey.io/form/kW1pz8u5QDxQf1wvAmjpB7", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((result) => {
      // Handle the result
      setName("");
      setEmail("");
      setPhoneNo("");
      setComment("");
    });
  };

  return (
    <>
      <div
        style={{
          color: "#fff",
          padding: "1% 4%",
          backgroundImage: `url(${feedback})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: x.matches ? "column" : "row",
        }}
      >
        <div style={{ width: x.matches ? "90vw" : "52vw" }}>
          <h1>Stock Market Mentorship Program </h1>
          {/* <img src={feedback}></img> */}
          <hr />
          <p></p>
        </div>
        <div style={{ width: x.matches ? "90vw" : "40vw" }}>
          <div className="form-enquire">Enquire Now</div>
          <form
            method="post"
            id="my-form"
            className="form-page"
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              name="Name"
              placeholder="Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
            <input
              type="tel"
              id="PhoneNo"
              name="Phone"
              value={phoneNo}
              placeholder="Phone No."
              onChange={(event) => setPhoneNo(event.target.value)}
              required
            />

            <input
              type="email"
              value={email}
              name="Email"
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Email"
              required
            />

            <textarea
              type="textarea"
              id="comment"
              name="Comment"
              value={comment}
              placeholder="Message"
              onChange={(event) => setComment(event.target.value)}
              required
            />
            <button
              type="submit"
              value="subm"
              style={{ backgroundColor: "#289dcc", fontWeight: "600" }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div className="course-page-details">
        <div>
          <h1>Stock Market Mentorship Program </h1>
          <hr />
          <p>
            A course designed to accelerate your growth, bring the best out of
            you and put it in the field of Stock Market. This module is a very
            in-depth Stock Market course. This comprehensive module delves deep
            into the Stock Market, curated by a team of seasoned traders who are
            dedicated to providing you immense guidance throughout this journey.
          </p>
        </div>
        <br/>
        <br/>
        <div>
          <h1>Stock Market Mentorship Program </h1>
          <hr />
          <p>
            A course designed to accelerate your growth, bring the best out of
            you and put it in the field of Stock Market. This module is a very
            in-depth Stock Market course. This comprehensive module delves deep
            into the Stock Market, curated by a team of seasoned traders who are
            dedicated to providing you immense guidance throughout this journey.
          </p>
        </div>
        <br/>
        <br/>
        <div style={{
          display: "flex",
          flexDirection: x.matches ? "column" : "row"
        }}>

          <div style={{ width: x.matches ? "90%" : "30%", lineHeight: 1.7 }}>
            <h1>Topics Covered</h1>
            <Accordion
              allowZeroExpanded="true"
            >
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={{backgroundColor: "#b7e9fd"}}>
                    <b>Module 1 : Basics of Stock Market</b>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    <li>Stock Market and Market Segment.</li>
                    <li>Primary Market – Working of IPOs.</li>
                    <li>Secondary Market – NSE/BSE Trading.</li>
                    <li>Stock Indices – Nifty / Sensex.</li>
                    <li>
                      Long Trade / Short Trade (Short Selling) (Live Example).
                    </li>
                    <li>Pre-Open Session - Bulk Deal Block Deal.</li>
                    <li>
                      Order Placement – Limit order / Market Order / SL Order.
                    </li>
                    <li>Advance Order – AMO / Bracket Orders/Cover Orders.</li>
                    <li>Margin trading / leverage – MIS trade and Carry Trade.</li>
                    <li>Circuit Breakers – On Stock / Index and Derivative.</li>
                    <li>Trade Clearance and Settlement.</li>
                    <li>Broker and Demat Account.</li>
                    <li>Factors Affecting the Market.</li>
                    <li>Commodities and Forex Market.</li>
                    <li>Linkages between Stock / Commodities and Forex.</li>
                    <li>Basic Terminologies of Market.</li>
                    <li>Contract Note.</li>
                    <li>Corporate Actions.</li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={{backgroundColor: "#b7e9fd"}}>
                    <b>Module 2 : Technical Analysis</b>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    <li>Introduction to Technical Analysis</li>
                    <li>Dow Theory and Technical Charts</li>
                    <li>Trendline Analysis + Live Trading</li>
                    <li>Support Resistance Traditional / Fractal Trading System</li>
                    <li>Gap trading for Morning Trade</li>
                    <li>Pivot Points and CPR and Reversal Candles System</li>
                    <li>Candlesticks Charting</li>
                    <li>Chart Patterns (will be revised with Derivative trade)</li>
                    <li>Moving Averages trend Analysis and trades on Nifty</li>
                    <li>Identifying Market Tops and Bottoms + Divergence</li>
                    <li>Fibonacci Retracement and Projection</li>
                    <li>Indicators and Oscillators</li>
                    <li>Traditional – RSI / MACD / Stochastic / Bollinger Band</li>
                    <li>Contemporary – VZONE / False Bar / Sinewave etc.</li>
                    <li>Live trading in Demo and Real Account</li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={{backgroundColor: "#b7e9fd"}}>
                    <b>Module 3 : Derivative Trading</b>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    <li>Introduction to Derivative</li>
                    <li>Introduction to Futures and Forwards Contracts</li>
                    <li>Futures Contract Terminology</li>
                    <li>Futures Margin + M2M</li>
                    <li>Leverage and Futures Pricing</li>
                    <li>Introduction to Options Contracts</li>
                    <li>Detailed explanation of Call Put with simple example</li>
                    <li>Option Buy and Option Selling</li>
                    <li>Option Margin for Buying and Selling</li>
                    <li>Option Premium and Moneyness of Option (ITM/OTM/ATM)</li>
                    <li>Option Greeks Introduction</li>
                    <li>Delta Introduction + Calculation</li>
                    <li>Theta and Vega + Introduction to Option Strategies</li>
                    <li>Straddle / Strangle / Covered Call + Protective Put</li>
                    <li>Spread – Bull Call / Bear Put</li>
                    <li>Income Generation Strategies - Selling ITM Sell</li>
                    <li>Trading Intraday Option – Nifty Bank Nifty</li>
                    <li>Hedging with Delta</li>
                    <li>Collar / Iron Condor / Butterfly / Ratio Spread</li>
                    <li>Formulation and Interpretation of Strategies</li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
          
          <div style={{marginLeft: x.matches ? "0" : "20%", width: x.matches ? "90%" : "50%"}}>
            <h1>Blogs from currencyveda</h1>
            <BlogsDynamic />
          </div>
        </div>
      </div>
    </>
  );
};

export default Smm;
