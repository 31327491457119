import React from "react";
import "./index.css";

function CourseOverview({ ref }) {
  
  
  return (
      <div>
        <button className="course-overview-button">Programs</button>
      </div>
  );
}

export default CourseOverview;
