import React, { useState } from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhoneFlip,
  faMobile,
  faMapLocation,
} from "@fortawesome/free-solid-svg-icons";

function ContactForm() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  let x = window.matchMedia("(max-width: 500px)");

  const handleSubmit = (event) => {
    // event.preventDefault();
    // console.log("Name:", name);
    // console.log("Phone:", phone);
    // console.log("Email:", email);
    // console.log("Comment:", comment);
    // fetch(
    //   "https://script.google.com/macros/s/AKfycbynHfsd_iMfZ5EUjEvXXhv5j_M4NXHZ7nDOw0NZ7JXwueAQsdkfdOc8TuVEa_HSRMq1Ng/exec",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       key1: "value1",
    //       key2: "value2",
    //     }),
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((data) => console.log(data))
    //   .catch((error) => console.error(error));
  };

  return (
    <div
      className="contact-page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <h1
          style={{
            margin: "0px 24px 50px",
            fontSize: "46px",
            textAlign: "center",
            color: "#000",
            paddingBottom: "10px",
            backgroundColor: "#fff",
            borderRadius: "15px",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "900",
            // textShadow: "2px 2px #000",
          }}
        >
          Contact Us
        </h1>
        <form
          action="https://api.sheetmonkey.io/form/2FcekZ1evLDGMpJfptL5eR"
          method="post"
          id="my-form"
          onSubmit={handleSubmit}
          className="contact-form"
        >
          <label>
            <span>Name:</span>{" "}
            <input
              type="text"
              value={name}
              name="Name"
              onChange={(event) => setName(event.target.value)}
              required
            />
          </label>
          <br />
          <label>
            <span>Phone:</span>{" "}
            <input
              type="tel"
              name="Phone"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </label>
          <br />
          <label>
            <span>Email:</span>{" "}
            <input
              type="email"
              value={email}
              name="Email"
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </label>
          <br />
          <label>
            <span>Comment:</span>{" "}
            <textarea
              rows="4"
              cols="50"
              value={comment}
              name="Comment"
              onChange={(event) => setComment(event.target.value)}
              onMouseDown={(event) => {
                if (event.target === document.activeElement) {
                  event.preventDefault();
                }
              }}
            />
          </label>
          <input
            type="hidden"
            name="Created"
            value="x-sheetmonkey-current-date-time"
          />
          <br />
          <button
            type="submit"
            value="subm"
            style={{ backgroundColor: "#289dcc", fontWeight: "600" }}
          >
            Submit
          </button>
        </form>
      </div>
      {/* <div style={{ marginLeft: "10%" }}> */}
      <div>
        <h1
          style={{
            margin: "0px 24px 50px",
            fontSize: "46px",
            textAlign: "center",
            color: "#000",
            paddingBottom: "10px",
            backgroundColor: "#fff",
            borderRadius: "15px",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "900",
            // textShadow: "2px 2px #000",
          }}
        >
          Reach Us!
        </h1>
        <div
          className="contact-form"
          // style={{ background: "#289dcc", color: "#fff" }}
          // marginRight: "60px"
        >
          <label>
            <a href="mailto:Education@currencyveda.com">
              <FontAwesomeIcon icon={faEnvelope} shake />
              <span
                style={
                  !x.matches ? { padding: "0 20px" } : { padding: "0 4px" }
                }
              >
                Education@currencyveda.com
              </span>
            </a>
          </label>
          <br />
          <label>
            <a href="tel:01171906960">
              <FontAwesomeIcon icon={faPhoneFlip} rotation={90} />
              <span
                style={{
                  padding: "0 20px",
                }}
              >
                011 7190 6960
              </span>
            </a>
          </label>
          <br />
          <label>
            <a href="tel:+917838134596">
              <FontAwesomeIcon
                icon={faMobile}
                style={{ "--fa-primary-opacity": "4" }}
              />
              <span
                style={{
                  padding: "0 20px",
                }}
              >
                +91 7838134596
              </span>
            </a>
          </label>
          <br />

          <label>
            <FontAwesomeIcon icon={faMapLocation} />
            <span
              style={{
                padding: "0 20px",
              }}
            >
              PLOT No -30, T/F, H. NO D-30A, Dayanand Block, Nirman Vihar,
              Shakarpur, New Delhi, Delhi 110092
            </span>
          </label>
          <br />
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
