import React, { useState } from "react";
import "./index.css";

function FormPopup({isOpen, setIsOpen}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [comment, setComment] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      Name: name,
      Email: email,
      PhoneNo: phoneNo,
      Comment: comment,
      From : "Popup"
    }

    fetch('https://api.sheetmonkey.io/form/kW1pz8u5QDxQf1wvAmjpB7', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((result) => {
      // Handle the result
      localStorage.removeItem("open");
      setIsOpen(false);
    });
    
  };

  return (
    (isOpen && <div className={"form-popup"}>
      <form
        id="my-form"
        onSubmit={handleSubmit}
      >
        <h2>Contact Us</h2>
        <span
          className="close"
          onClick={(e) => {
            setIsOpen(false);
          }}
        >
          &times;
        </span>
        <input
          type="text"
          name="Name"
          placeholder="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          required
        />
        <input
          type="tel"
          id="PhoneNo"
          name="Phone"
          value={phoneNo}
          placeholder="Phone No."
          onChange={(event) => setPhoneNo(event.target.value)}
          required
        />

        <input
          type="email"
          value={email}
          name="Email"
          onChange={(event) => setEmail(event.target.value)}
          placeholder="Email"
          required
        />

        <textarea
          type="textarea"
          id="comment"
          name="Comment"
          value={comment}
          placeholder="Message"
          onChange={(event) => setComment(event.target.value)}
          required
        />
        <input
          type="hidden"
          name="Created"
          value="x-sheetmonkey-current-date-time"
        />
        <button type="submit" value="subm">
          Submit
        </button>
      </form>
      {/* </div> */}
    </div>)
  );
}

export default FormPopup;
