import React from "react";
import icon from "../Store/icon.jpg";
import "./index.css";

function Header() {
  return (
    <>
      <div
        style={{
          width: "100%",
          textAlign: "center",
          // padding: "0px 0",
          backgroundColor: "#35d4ff",
          color: "white",
          fontWeight: "700",
        }}
      >
        <div className="headerMove move">
          Learn basics to advance of the financial markets with Currencyveda
        </div>
      </div>
      <header>
        <a href="https://currencyveda.com">
          <img
            style={{
              width: "191px",
              height: "100%",
            }}
            src={icon}
            alt="Logo"
          />
        </a>
        <nav>
          <a href="https://blogs.currencyveda.com/">Blogs</a>
          <a href="#">Courses</a>
          <a href="tel:+919717337467">+91-97173 37467</a>
        </nav>
      </header>
    </>
  );
}

export default Header;
