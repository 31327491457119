import React from "react";
// import course1 from "../Store/course-one.png";
// import course2 from "../Store/course-two.png";
// import course3 from "../Store/course-three.png";
import course1 from "../Store/1.png";
import course2 from "../Store/2.png";
import course3 from "../Store/3.png";
import { Link } from "react-router-dom";

function CourseCarousel() {
  const x = window.matchMedia("(max-width: 500px)");
  // const courses = [
  //   {
  //     id: 1,
  //     image: person,
  //     author: "Currency Veda",
  //     topic: "Currency Market",
  //     description: "In this course, we will study about the currency market.",
  //     stars: 4.5,
  //     price: "₹19999.99",
  //     Link: "/course1",
  //   },
  //   {
  //     id: 2,
  //     image: person,
  //     author: "Currency Veda",
  //     topic: "Equity Market",
  //     description: "In this course, we will study about the equity market.",
  //     stars: 4.2,
  //     price: "₹2999.99",
  //     Link: "/course2",
  //   },
  //   {
  //     id: 3,
  //     image: person,
  //     author: "Currency Veda",
  //     topic: "Advanced Option Trading",
  //     description:
  //       "In this course, we will study about the advanced option trading.",
  //     stars: 4.8,
  //     price: "₹ 3999.99",
  //     Link: "/course3",
  //   },
  // ];

  return (
    <div
      className="courses"
      style={{
        display: "flex",
        overflow: "hidden",
        padding: "6px 20px",
      }}
    >
      <div
        className="course-items"
        style={{
          width: x.matches ? "300px" : "360px",
          marginRight: "30px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <img src={course1} alt={"course"} style={{ width: "100%" }} />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Link to={"/aot"} className="course-view-button">
            View Program
          </Link>
        </div>
      </div>
      <div
        className="course-items"
        style={{
          width: x.matches ? "300px" : "360px",
          marginRight: "30px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <img src={course2} alt={"course"} style={{ width: "100%" }} />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Link to={"/smm"} className="course-view-button">
            View Program
          </Link>
        </div>
      </div>
      <div
        className="course-items"
        style={{
          width: x.matches ? "300px" : "360px",
          marginRight: "30px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <img src={course3} alt={"course"} style={{ width: "100%" }} />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Link to={"/ctm"} className="course-view-button">
            View Program
          </Link>
        </div>
      </div>
      {/* {courses.map((course) => (
        <div
          className="course-items"
          key={course.id}
          style={{
            width: x.matches ? "300px" : "360px",
            marginRight: "30px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <img
            src={course.image}
            alt={course.topic}
            style={{ width: "100%" }}
          />
          <div style={{ padding: "10px" }}>
            <p style={{ margin: "0", fontSize: "14px", color: "#999" }}>
              By {course.author}
            </p>
            <h3 style={{ margin: "10px 0 0", fontSize: "18px" }}>
              {course.topic}
            </h3>
            <p style={{ margin: "10px 0", fontSize: "14px", height: "40px" }}>
              {course.description}
            </p>
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "5px", fontSize: "14px" }}>
                {course.stars}
              </span>
              <img
                src="https://img.icons8.com/material-outlined/24/000000/star--v1.png"
                alt="star"
              />
            </div> 
            <hr style={{ margin: "10px 0" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                {course.price}
              </span>
              <button
                style={{
                  background: "linear-gradient(135deg, #FF8C00, #FFA500)",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "bold",
                  border: "none",
                  borderRadius: "20px",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
              >
                <Link
                  to={course.Link}
                  style={{
                    background: "linear-gradient(135deg, #FF8C00, #FFA500)",
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    border: "none",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  Enroll Now
                </Link>
              </button>
            </div>
          </div>
        </div>
      ))} */}
    </div>
  );
}

export default CourseCarousel;
