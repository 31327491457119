import React, { useEffect, useState } from "react";

import "./styles.css";
import feedback from "./../../Store/Feedback.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import BlogsDynamic from "../DynamicBlogs/BlogsDynamic";

const Ctm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [comment, setComment] = useState("");
  let x = window.matchMedia("(max-width: 500px)");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      Name: name,
      Email: email,
      PhoneNo: phoneNo,
      Comment: comment,
      From: "SMM Course Page",
    };

    fetch("https://api.sheetmonkey.io/form/kW1pz8u5QDxQf1wvAmjpB7", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((result) => {
      // Handle the result
      setName("");
      setEmail("");
      setPhoneNo("");
      setComment("");
    });
  };

  return (
    <>
      <div
        style={{
          color: "#fff",
          padding: "1% 4%",
          backgroundImage: `url(${feedback})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: x.matches ? "column" : "row",
        }}
      >
        <div style={{ width: x.matches ? "90vw" : "52vw" }}>
          <h1>Currency Trading Mentorship Program </h1>
          {/* <img src={feedback}></img> */}
          <hr />
          <p></p>
        </div>
        <div style={{ width: x.matches ? "90vw" : "40vw" }}>
          <div className="form-enquire">Enquire Now</div>
          <form
            method="post"
            id="my-form"
            className="form-page"
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              name="Name"
              placeholder="Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
            <input
              type="tel"
              id="PhoneNo"
              name="Phone"
              value={phoneNo}
              placeholder="Phone No."
              onChange={(event) => setPhoneNo(event.target.value)}
              required
            />

            <input
              type="email"
              value={email}
              name="Email"
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Email"
              required
            />

            <textarea
              type="textarea"
              id="comment"
              name="Comment"
              value={comment}
              placeholder="Message"
              onChange={(event) => setComment(event.target.value)}
              required
            />
            <button
              type="submit"
              value="subm"
              style={{ backgroundColor: "#289dcc", fontWeight: "600" }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div className="course-page-details">
        <div>
          <h1>Currency Trading Mentorship Program</h1>
          <hr />
          <p>
            Welcome to our Currency Trading Mentorship Program (CTM)! We've
            carefully crafted this course to help you step confidently into the
            world of currency trading. Our experienced trading mentors, with
            tons of in-hand experiences , are here to guide you in every step of
            the way. We're not just teachers, we will be your partners on this
            exciting journey and we are committed to making you a
            successful trader !
          </p>
        </div>
        <br/>
        <br/>
        <div>
          <h1>Currency Trading Mentorship Program</h1>
          <hr />
          <p>
            Welcome to our Currency Trading Mentorship Program (CTM)! We've
            carefully crafted this course to help you step confidently into the
            world of currency trading. Our experienced trading mentors, with
            tons of in-hand experiences , are here to guide you in every step of
            the way. We're not just teachers, we will be your partners on this
            exciting journey and we are committed to making you a
            successful trader !
          </p>
        </div>
        <br/>
        <br/>
        <div style={{
          display: "flex",
          flexDirection: x.matches ? "column" : "row"
        }}>

          <div style={{ width: x.matches ? "90%" : "30%", lineHeight: 1.7 }}>
            <h1>Topics Covered</h1>
            <Accordion
              allowZeroExpanded="true"
            >
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={{backgroundColor: "#b7e9fd"}}>
                    <b>Module 1 : Currency Trading Topics</b>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    <li>History of Currency Exchange</li>
                    <li>What is Forex Market</li>
                    <li>What are Major Currencies</li>
                    <li>Cross Rate in Currency market</li>
                    <li>Technical Analysis in Currency trading</li>
                    <li>Impact of Market Economics and Events important for currency trading in India</li>
                    <li>Currency Derivatives</li>
                    <li>Options Trading in Currency Market</li>
                    <li>Exchange Traded Currency Futures</li>
                    <li>Advantages of Currency Futures</li>
                    <li>Strategies on Currency Futures</li>
                    <li>Advance strategies in Currencies Options</li>
                    <li>Risk Management</li>
                    <li>Live Trading Practical’s on Currency Market</li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
          
          <div style={{marginLeft: x.matches ? "0" : "20%", width: x.matches ? "90%" : "50%"}}>
            <h1>Blogs from currencyveda</h1>
            <BlogsDynamic />
          </div>
        </div>
      </div>
    </>
  );
};

export default Ctm;
