import React, { useEffect, useRef, useState } from "react";
import Header from "./Components/Header";
import Banner from "./Components/Banner";
import CourseOverview from "./Components/CourseOverview";
import CourseCarousel from "./Components/CourseCarousel";
import JoiningBenefit from "./Components/JoiningBenefit";
import Footer from "./Components/Footer";
import Testimonial from "./Components/Testimonial";
import ContactForm from "./Components/ContactForm";
import Description from "./Components/Description";
import Problems from "./Store/pain points.png";
import Solutions from "./Store/solutions.png";
import ContactInformation from "./Components/ContactInformation";
import ImageSection from "./Components/ImageSection";
import FormPopup from "./Components/FormPopup";
import About from "./Store/ABOUT US 21 AUG1.png";

function Courses() {
  const [isOpen, setIsOpen] = useState(localStorage.getItem("open"));
  const ref = useRef(null);

  useEffect(() => {
    localStorage.setItem("open", true);
  }, [])

  const handleClick = () => {
    console.log("Handle Click");
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div style={{ marginTop: "5px" }} onClick={handleClick}>
        <Banner />
      </div>
      <div
        style={{
          marginTop: "60px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Description /> */}
        <img width="92%" src={About} alt="about" />
      </div>
      <FormPopup isOpen={isOpen} setIsOpen={setIsOpen}/>
      <div
        style={{
          marginTop: "60px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <JoiningBenefit />
      </div>
      <div
        ref={ref}
        style={{
          marginTop: "120px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CourseOverview />
      </div>

      <div
        style={{
          marginTop: "65px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CourseCarousel />
      </div>
      {/* <div
        style={{
          marginTop: "65px",
          width: "98.8vw"
        }}
      >
        <img width="100%" src={Problems} alt="problems"/>
      </div>
      <div
        style={{
          marginTop: "0",
          width: "100%",
          textAlign: "center"
        }}
      >
        <img width="80%" src={Solutions} alt="solutions"/>
      </div> */}
      {/* <div
        style={{
          marginTop: "90px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <JoiningBenefit />
      </div> */}
      {!isOpen && <div>
        <Testimonial />
      </div>}
      <div
        style={{
          marginTop: "90px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ContactForm />
      </div>
    </div>
  );
}

export default Courses;
