import React from "react";
import TestimonialSlider from "./TestimonialSlider";
import pp1 from "../Store/pp1.jpg";
import pp2 from "../Store/pp2.jpg";
import pp3 from "../Store/pp3.jpg";
import pp4 from "../Store/pp4.jpg";
import pp5 from "../Store/pp5.jpg";
import pp6 from "../Store/pp6.png";

const Testimonial = () => {
  const testimonials = [
    {
      profilePic: pp1,
      stars: 4,
      author: `Kunal`,
      quote:
        "The sessions helped me to gain confidence to trade/invest in stock market directly without being dependent on anyone else. I am now confident enough to trade.",
    },
    {
      profilePic: pp2,
      stars: 5,
      author: `Arpit`,
      quote:
        "Wonderful faculties and excellent administration for the financial modelling course. You will learn new in every single class even if you have good amount of experience in finance field..",
    },
    {
      profilePic: pp5,
      stars: 5,
      author: `Shristi`,
      quote:
        "Very good approach from the staff and they will understanding the need of customer. Advising the customer with greatest extent. Updating the loan information from time to time is very much appreciated.",
    },
    {
      profilePic: pp3,
      stars: 4,
      author: `Ronak`,
      quote:
        "This is a Very interesting and profitable concept. Have benefited me a lot lately during these hard times. is a master mind genius and knows his way around. I am glad to be part of it.wonderful",
    },
    {
      profilePic: pp4,
      stars: 5,
      author: `Kartik`,
      quote:
        "The institute provides highly qualified faculties for teaching different sections of the curriculum. Nice teaching methodology adopted. Definitely recommended for fellow students!",
    },

    {
      profilePic: pp6,
      stars: 5,
      author: `Sheshta`,
      quote:
        "It's great place to learn about stock market and investment. I must say they are the best & genuine who can provide deep knowledge",
    },
  ];

  return (
    <div>
      <h1
        style={{
          margin: "111px 0",
          fontSize: "46px",
          textAlign: "center",
          color: "#000",
          marginBottom: "15px",
          fontFamily: "'Poppins', sans-serif",
          fontWeight: "900",
          // textShadow: "2px 2px #000",
        }}
      >
        Testimonials
      </h1>
      <TestimonialSlider testimonials={testimonials} />
    </div>
  );
};

export default Testimonial;
