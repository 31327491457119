import React, { useState, useEffect } from "react";
import axios from "axios";
import { Autoplay, Pagination, Navigation } from "swiper";
import "./blog.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

const BlogsDynamic = () => {
  var x = window.matchMedia("(max-width: 500px)");
  // console.log(x.matches);

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios
      .get("https://blogs.currencyveda.com/wp-json/wp/v2/posts")
      .then((response) => {
        setPosts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div
      style={{
        color: "#000",
        height: "100%",
        padding: "0",
      }}
    >
      {/* <Swiper
        modules={[Autoplay, Navigation]}
        spaceBetween={2}
        slidesPerView={x.matches ? 1 : 4}
        autoplay={{ delay: 5000 }}
        pagination={{ clickable: true }}
        navigation={!x.matches}
      >
        {posts.map((post) => (
          <SwiperSlide key={post.id}>
            <a
              className="dynamicblogs"
              href={post.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                  textAlign: "center",
                }}
              >
                <img
                  src={post.yoast_head_json.og_image[0].url}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "60%",
                    marginBottom: "20px",
                    borderRadius: "20px 20px 0px 0px",
                  }}
                />
                <p
                  style={{
                    fontStyle: "italic",
                    fontSize: "15px",
                    fontWeight: "bold",
                    // marginBottom: "5px",
                  }}
                >
                  {post.title.rendered}
                </p>
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper> */}
      <ol>
      {posts.slice(0,7).map((post) => (
          <li key={post.id}>
            <a
              className="dynamicblogs"
              href={post.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                style={{
                  // display: "flex",
                  // flexDirection: "column",
                  // alignItems: "center",
                  // justifyContent: "start",
                  // textAlign: "center",
                }}
              >
                {/* <img
                  src={post.yoast_head_json.og_image[0].url}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "60%",
                    marginBottom: "20px",
                    borderRadius: "20px 20px 0px 0px",
                  }}
                /> */}
                <p
                  style={{
                    fontStyle: "italic",
                    fontSize: "15px",
                    fontWeight: "bold",
                    // marginBottom: "5px",
                  }}
                >
                  {post.title.rendered}
                </p>
              </div>
            </a>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default BlogsDynamic;
