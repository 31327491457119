import React from "react";
import { useRef } from "react";
import Courses from "./Courses";
import { Routes, Route, HashRouter } from "react-router-dom"; // import HashRouter
import Course1 from "./Components/CoursePages/Course1";
import Course2 from "./Components/CoursePages/Course2";
import Ctm from "./Components/CoursePages/Ctm";
import Smm from "./Components/CoursePages/Smm";
import Aot from "./Components/CoursePages/Aot";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const App = () => {
  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };
  const onLoad = () => {
    console.log("onLoad works!");
  };

  return (
    <>
      <div>
        <Header />
        <hr
          style={{
            border: "0.5px solid black",
          }}
        />
      </div>
      <HashRouter>
        <Routes>
          <Route index element={<Courses />} />
          <Route path="/" element={<Courses />} exact />
          <Route path="/course1" element={<Course1 />} />
          <Route path="/course2" element={<Course2 />} />
          <Route path="/smm" element={<Smm />} />
          <Route path="/aot" element={<Aot />} />
          <Route path="/ctm" element={<Ctm />} />
          <Route path="*" element={<Courses />} />
        </Routes>
      </HashRouter>{" "}
      <div className="App">
        <TawkMessengerReact
          propertyId="64f1e572a91e863a5c1110cf"
          widgetId="1h98egkc2"
          ref={tawkMessengerRef}
          onLoad={onLoad}
        />
      </div>
      <div
        style={{
          marginTop: "90px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Footer />
      </div>
    </>
  );
};

export default App;
