import React, { useState, useEffect } from "react";

import "./styles.css";
import feedback from "./../../Store/Home.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import BlogsDynamic from "../DynamicBlogs/BlogsDynamic";

const Aot = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [comment, setComment] = useState("");
  let x = window.matchMedia("(max-width: 500px)");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      Name: name,
      Email: email,
      PhoneNo: phoneNo,
      Comment: comment,
      From: "AOT Course Page",
    };

    fetch("https://api.sheetmonkey.io/form/kW1pz8u5QDxQf1wvAmjpB7", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((result) => {
      // Handle the result
      setName("");
      setEmail("");
      setPhoneNo("");
      setComment("");
    });
  };

  return (
    <>
      <div
        style={{
          color: "#fff",
          padding: "1% 4%",
          backgroundImage: `url(${feedback})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: x.matches ? "column" : "row",
        }}
      >
        <div style={{ width: x.matches ? "90vw" : "52vw" }}>
          <h1>Advance Option Trading Masterclass </h1>

          <hr />
          <p></p>
        </div>
        <div style={{ width: x.matches ? "90vw" : "40vw" }}>
          <div className="form-enquire">Enquire Now</div>
          <form
            method="post"
            id="my-form"
            className="form-page"
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              name="Name"
              placeholder="Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
            <input
              type="tel"
              id="PhoneNo"
              name="Phone"
              value={phoneNo}
              placeholder="Phone No."
              onChange={(event) => setPhoneNo(event.target.value)}
              required
            />

            <input
              type="email"
              value={email}
              name="Email"
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Email"
              required
            />

            <textarea
              type="textarea"
              id="comment"
              name="Comment"
              value={comment}
              placeholder="Message"
              onChange={(event) => setComment(event.target.value)}
              required
            />
            <button
              type="submit"
              value="subm"
              style={{ backgroundColor: "#289dcc", fontWeight: "600" }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div className="course-page-details">
        <div>
          <h1>Advance Option Trading Masterclass </h1>
          <hr />
          <p>
            We have discussed and researched with a team of brilliant succesful
            traders, to built AOT. An Advanced Option Trading Mentorship
            Program, this course will help you master the world of advanced
            options trading. Our skilled mentors will be your guide, throughout
            this program. This course will equip you with all the essential
            knowledge to get yourself started. So, get ready to learn, grow, and
            conquer the world of options trading with us!
          </p>
        </div>
        <br/>
        <br/>
        <div>
          <h1>Advance Option Trading Masterclass </h1>
          <hr />
          <p>
            We have discussed and researched with a team of brilliant succesful
            traders, to built AOT. An Advanced Option Trading Mentorship
            Program, this course will help you master the world of advanced
            options trading. Our skilled mentors will be your guide, throughout
            this program. This course will equip you with all the essential
            knowledge to get yourself started. So, get ready to learn, grow, and
            conquer the world of options trading with us!
          </p>
        </div>
        <br/>
        <br/>
        <div style={{
          display: "flex",
          flexDirection: x.matches ? "column" : "row"
        }}>

          <div style={{ width: x.matches ? "90%" : "30%", lineHeight: 1.7 }}>
            <h1>Topics Covered</h1>
            <Accordion
              allowZeroExpanded="true"
            >
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={{backgroundColor: "#b7e9fd"}}>
                    <b>Module 1 : Advance Options Topics</b>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    <li>Understand Options Trading & How Options Trading Works</li>
                    <li>Learn How to Trade Call & Put Options Effectively</li>
                    <li>Why is Time Decay So Important for Options Trading</li>
                    <li>Build a Profitable System for Options Trading</li>
                    <li>Advanced Options Strategies the Right Way</li>
                    <li>Advanced Bullish Strategies</li>
                    <li>Short Naked Put Strategy</li>
                    <li>Married Put Options Trading Strategy</li>
                    <li>Difference Between Weekly, Monthly, and Quarterly Options Trading</li>
                    <li>Bear Call Ladder Strategy</li>
                    <li>Long Combo Strategy</li>
                    <li>Call Ratio Backspread Strategy</li>
                    <li>Collar Strategy</li>
                    <li>Advanced Bearish Strategies</li>
                    <li>Short Naked Call Strategy</li>
                    <li>Bull Put Ladder Strategy</li>
                    <li>Short Combo Strategy</li>
                    <li>Put Ratio Backspread Strategy</li>
                    <li>How to Trade Advanced Neutral Strategies</li>
                    <li>Long Call Condor Strategy</li>
                    <li>Long Put Condor Strategy</li>
                    <li>Short Straddle Strategy</li>
                    <li>Strangle Strategy</li>
                    <li>Risk Management Options Trading Portfolio</li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
          
          <div style={{marginLeft: x.matches ? "0" : "20%", width: x.matches ? "90%" : "50%"}}>
            <h1>Blogs from currencyveda</h1>
            <BlogsDynamic />
          </div>
        </div>
      </div>
    </>
  );
};

export default Aot;
